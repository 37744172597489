import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TrustPilot from "../components/TrustPilot";


export const OpenPageTemplate = ({ image, title, heading, subheading, mainpitch, description, intro }) => (
  <div>
    <Helmet>
      <title>Swiftfox | Open an Account</title>
    </Helmet>
    <div className="hero-section home-hero">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Choose your Account
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Open a personal or business account with us, and start managing your money, undeniably swift.
                </motion.h4>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 1, delay: 1.2, ease: "easeInOut" }} className="button-container stacker">
                  <div className="blue-block">
                    <h4>Business Account</h4>
                    <p>Swiftfox Provide The Service And The Advice That Will Protect The Profitability Of Your International Transactions.</p>
                    {/* <Link className="main-button-alt" to="/corporate-account">
                    Apply for a Business Account Link
                  </Link> */}
                  <a className="main-button-alt" href="https://onboarding.paydirect.io/swiftfox/forms/corporate">Apply for a Business Account</a>
                  </div>
                  <div className="grey-block">
                    <h4>Personal Account</h4>
                    <p>Our customers typically save up to 4% when converting currency compared to using a bank.</p>
                    {/* <Link className="main-button-alt" to="/individual-account">
                    Apply for a Personal Account
                  </Link> */}
                  <a className="main-button-alt" href="https://onboarding.paydirect.io/swiftfox/forms/individual">Apply for a Personal Account</a>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <div className="hero-banner">
        <InView threshold={0.1}>
          {({ ref, inView }) => (
            <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.25, ease: "easeInOut" }} className="hero-banner-content">
                <div className="section-item">
                  <p>We help ensure every penny is accounted for no matter where it goes - whether employees at home or suppliers abroad.</p>
                </div>
                <div className="section-item">
                  {/* <div className="banner-images">
                    <img alt="Trust Pilot" src="../img/trust-pilot.svg"/>
                  </div> */}
                </div>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="callout-section callout-bg dark-text">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item white-text">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Your expert currency exchange broker
                </motion.h2>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.p ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  There are countless ways to transfer money abroad. The most important consideration is the timing and mode of a transaction. When you exchange currencies with Swiftfox, we help you find that sweet spot, reducing your risk and maximising returns.
                </motion.p>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="callout-section dark-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item">
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} className="module-stack widtho">
                    <TrustPilot />
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Fast, Reliable and Secure.</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  The security and privacy of your data are of the highest importance to us. That’s why we’ve partnered with The Currency Cloud Limited as our payment service provider. They are one of the world's top payment engines, designed to protect the safety and security of customers' money.
                </motion.h4>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>


  </div>
);

OpenPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const OpenPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <OpenPageTemplate image={frontmatter.image} title={frontmatter.title} heading={frontmatter.heading} subheading={frontmatter.subheading} mainpitch={frontmatter.mainpitch} description={frontmatter.description} intro={frontmatter.intro} />
    </Layout>
  );
};

OpenPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default OpenPage;

export const pageQuery = graphql`
  query OpenPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
